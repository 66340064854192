import React from 'react'

import { Container } from 'components/Container'
import { T_Padding } from 'common/types/Component'

export interface VideoProps {
    blok: {
        url: string
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Video: React.FC<VideoProps> = ({ blok }) => {
    const { url, paddingTop, paddingBottom } = blok
    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom} isFluid={false}>
            <div className="iframe-container">
                <iframe
                    className="iframe"
                    src={url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </Container>
    )
}
