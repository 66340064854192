import React, { useState } from 'react'

import { Container } from 'components/Container'
import { Headline } from 'components/Headline'
import { IconWrapper } from 'components/Icon'
import { T_Padding } from 'common/types/Component'

export interface ProcessStudyProps {
    blok: {
        paddingTop: T_Padding
        paddingBottom: T_Padding
        title: string
        image?: {
            filename: string
            key: string
            alt: string
        }
    }
}

export const ProcessStudy: React.FC<ProcessStudyProps> = ({ blok }) => {
    const { title, paddingTop, paddingBottom, image } = blok
    const index = 0
    const [activeIndex, setActiveIndex] = useState(-1)
    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <div
                onClick={() => setActiveIndex((aI) => (aI === -1 ? 0 : -1))}
                className={`accordeon__item accordeon__item--big ${activeIndex === index ? 'accordeon__item--is-open' : ''}`}
            >
                <div className="accordeon__item__header">
                    <Headline tag={'h3'} classes="h3" content={title} paddingTop={'none'} paddingBottom={'none'} />
                    <IconWrapper isOpen={activeIndex === index} />
                </div>
                <div className="accordeon__item__body">
                    <div className="gap-t-xl">{image && <img src={image.filename} alt={image.alt} />}</div>
                </div>
            </div>
        </Container>
    )
}
