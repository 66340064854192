import React from 'react'
import { Helmet } from 'react-helmet'

export interface SeoProps {
    title: string
    description: string
}

export const Seo: React.FC<SeoProps> = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}
