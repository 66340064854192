import React from 'react'
import { Richtext } from 'storyblok-js-client'

import { sbClient } from 'hooks/useStoryblok'
import { T_Padding } from 'common/types/Component'
import { Container } from 'components/Container'

export interface LongtextProps extends I_Component {
    blok: {
        classes?: string
        content: Richtext
        paddingBottom: T_Padding
        paddingTop: T_Padding
    }
}

export const Longtext: React.FC<LongtextProps> = ({ blok }) => {
    const { content, paddingTop, paddingBottom } = blok
    const html = sbClient.richTextResolver.render(content)
    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom} isFluid={false}>
            <div className="longtext" dangerouslySetInnerHTML={{ __html: html }}></div>
        </Container>
    )
}

export default Longtext
