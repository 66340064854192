import React from 'react'

import { T_Padding } from 'common/types/Component'
import { I_Component } from 'common/types/Component'
import { Container } from 'components/Container'

export interface ContentProps extends I_Component {
    blok: {
        classes?: string
        content: string
        paddingBottom: T_Padding
        paddingTop: T_Padding
    }
}

export const Content: React.FC<ContentProps> = ({ blok }) => {
    const { content, classes, paddingTop, paddingBottom } = blok
    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <div className={`row ${classes}`}>
                <div className="col-12 offset-md-4 col-md-8 text fnt-utl-line-height--text" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </Container>
    )
}
