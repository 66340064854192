import React from 'react'

import { I_Component, T_Padding } from 'common/types/Component'
import { Container } from 'components/Container'
import { Headline, HeadlineProps } from 'components/Headline'

export interface TeaserTextImageProps extends I_Component {
    blok: {
        headline: string
        subline: string
        content: string
        classes: string
        paddingTop: T_Padding
        paddingBottom: T_Padding
        image: {
            alt: string
            filename: string
        }
    }
    image: {
        key: string
        alt: string
    }
    classes?: string
    headline: HeadlineProps
    content: string
}

export const TeaserTextImage: React.FC<TeaserTextImageProps> = ({ blok }) => {
    const { paddingTop, paddingBottom, headline, subline, content, image, classes } = blok

    const headlineProps: HeadlineProps = {
        content: headline,
        tag: 'h3',
        classes: 'h3 fnt-utl-wei--500',
        paddingTop: 'none',
        paddingBottom: 'xl'
    }
    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom} isFluid={false}>
            <div className={`teaser-text-image ${classes}`}>
                <div className="teaser-text-image__figure">
                    <img src={image.filename} alt={image.alt} />
                </div>
                <div className="teaser-text-image__content">
                    <div className="teaser-text-image__content__inner">
                        <Headline {...headlineProps} />
                        {subline && (
                            <p className="text fnt-utl-wei--500">
                                22 verschiedene Wege in deine berufliche Zukunft findest du am Industrie Center Obernburg - kurz ICO.
                            </p>
                        )}
                        <div className="gap-t-l body" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
            </div>
        </Container>
    )
}
