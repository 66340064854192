import React from 'react'

export interface IconWrapperProps {
    isOpen: boolean
}

export const IconWrapper: React.FC<IconWrapperProps> = ({ isOpen }) => {
    return (
        <svg viewBox="0 0 60 60" width="60" height="60" className="icon-wrapper">
            <circle cx="30" cy="30" r="25" className="icon-wrapper__circle icon-wrapper__circle--interactive"></circle>
            <circle cx="30" cy="30" r="25" className="icon-wrapper__circle"></circle>
            <text x="20" y="39" className="icon-wrapper__text">
                {isOpen ? '×' : '+'}
            </text>
        </svg>
    )
}
