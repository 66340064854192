import React, { useState, useRef, useEffect } from 'react'
import { sortBy } from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'

import { convertStoryContent } from 'hooks/useStoryblok'
import { useWindowSize } from 'hooks/useWindow'
import { TransitionGlobal } from 'common/animations'
import { getAllCategories, allStorybookEntry } from 'common/query'
import { I_Category as I_Cat, I_Category } from 'common/query/allJobCategories'
import { I_Page } from 'common/types/Page'
import { Accordeon } from 'components/Accordeon'
import { Category } from './Category'

export interface I_GroupedCategory {
    entry: {
        content: {
            content: any[]
        }
    }
    page: Partial<I_Page>
}
export interface ApprenticeshipsProps {
    location: Location
}

export const Apprenticeships: React.FC<ApprenticeshipsProps> = ({ location }) => {
    const { width } = useWindowSize()
    const refTile = useRef<HTMLDivElement>(null)
    const [shouldDrag, setShouldDrag] = useState<boolean>(false)
    const [activeIndexState, setActiveIndexState] = useState<number | undefined>(undefined)
    const [categoriesState, setCategoriesState] = useState<I_GroupedCategory[]>([])

    useEffect(() => {
        if (width && width >= 769) {
            setShouldDrag(() => false)
        } else if (width && width < 769) {
            setShouldDrag(() => true)
        }
    }, [width])

    const cats = getAllCategories() as { category: I_Cat }[]
    const allEntries = allStorybookEntry()

    const buildCategoriesOverviewAndRender = (category: any, index: number) => {
        const converted = convertStoryContent(category.category)

        const groupedCategories = allEntries.allStoryblokEntry.entries.filter(({ entry }) => {
            if (entry.field_component !== 'page') return
            const story = convertStoryContent(entry as any)

            return story.content.categoryType === converted.uuid
        })

        return (
            <Category
                category={converted.content}
                groupedCategories={groupedCategories}
                index={index}
                activeIndex={activeIndexState}
                key={index}
                delegateClick={(category, index) => {
                    setCategoriesState(category)
                    setActiveIndexState(index)
                    setTimeout(() => {
                        if (typeof window !== 'undefined' && refTile && refTile.current) {
                            window.scrollTo(0, refTile.current.getBoundingClientRect().top + window.scrollY - 200)
                        }
                    }, 0)
                }}
            />
        )
    }

    const checkAmpersand = (title: string) => {
        const position = title.indexOf('&')
        return position === -1 ? title : [title.slice(0, position), '%', title.slice(position)].join('')
    }

    const renderJobs = (categoriesState: any[]) => {
        const items: any[] = categoriesState.map(({ entry: item }, index) => {
            return {
                title: item.name ? checkAmpersand(item.name) : '',
                description: item.content.content ? item.content.content[0].description : '',
                activeIndex: undefined,
                link: item.slug,
                index: index
            }
        })

        return <Accordeon items={sortBy(items, ({ title }) => title)} />
    }

    const renderDragWrapper = () => {
        return (
            <motion.div
                drag={'x'}
                dragConstraints={{ left: -(240 * (cats.length - 1)), right: 0 }}
                style={{ width: 240 * cats.length }}
                className={`${'drag-tiles'}`}
            >
                {cats.map((category: any, index: number) => buildCategoriesOverviewAndRender(category, index))}
            </motion.div>
        )
    }

    return (
        <React.Fragment>
            <div id={'berufe'} className="container gap-t-xl">
                {shouldDrag ? (
                    renderDragWrapper()
                ) : (
                    <div className={`${'row gy-3 gy-xl-0'}`}>
                        {cats.map((category: any, index: number) => buildCategoriesOverviewAndRender(category, index))}
                    </div>
                )}
            </div>
            <div ref={refTile}>
                <AnimatePresence exitBeforeEnter>
                    {categoriesState && categoriesState.length > 0 ? (
                        <motion.div key={'accordeon-container'} className="gap-t-xl">
                            <motion.div
                                key={'jobs-container'}
                                initial={{ maxHeight: 0, opacity: 0 }}
                                animate={{ maxHeight: 2000, opacity: 1 }}
                                exit={{ maxHeight: 0, opacity: 0 }}
                                transition={TransitionGlobal(0.25, 0)}
                                className="apprenticeships apprenticeships--is-open"
                            >
                                {renderJobs(categoriesState)}
                            </motion.div>
                        </motion.div>
                    ) : (
                        ''
                    )}
                </AnimatePresence>
            </div>
        </React.Fragment>
    )
}
