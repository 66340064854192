import React from 'react'

import { Accordeon } from '../Accordeon'
import { Apprenticeships } from '../Apprenticeships'
import { Banner } from '../Banner'
import { Bullets } from '../Bullets'
import { Content } from '../Content'
import { Duration } from '../Duration'
import { Longtext } from '../Longtext'
import { HeadlineSection as Headline } from '../Headline'
import { Hero } from '../Heros/Hero'
import { Map } from '../Map'
import { Recommendations } from '../Recommendations'
import { Persons } from '../Persons'
import { Jobs } from '../Jobs'
import { TeaserTextImage } from '../Teasers'
import { Internships } from '../Internships'
import { Video } from '../Video'
import { Process } from '../Process'
import { ProcessStudy } from '../Process/ProcessStudy'

export interface DynamicComponentProps {
    blok: any
    location: Location
}

const Components = {
    accordion: Accordeon,
    apprenticeships: Apprenticeships,
    banner: Banner,
    bullets: Bullets,
    content: Content,
    duration: Duration,
    headline: Headline,
    hero: Hero,
    internships: Internships,
    longtext: Longtext,
    jobs: Jobs,
    map: Map,
    persons: Persons,
    process: Process,
    processStudy: ProcessStudy,
    recommendations: Recommendations,
    teaserTextImage: TeaserTextImage,
    video: Video
}

export const DynamicComponent: React.FC<DynamicComponentProps> = ({ blok, location }) => {
    if (typeof Components[blok.component] !== 'undefined') {
        const Component = Components[blok.component]
        return <Component blok={blok} key={blok._uid} location={location} />
    }

    return null
}
