import React from 'react'

import { I_Component, T_Padding } from 'common/types/Component'
import { Container } from 'components/Container'
import { Headline } from 'components/Headline'

export interface DurationProps {
    blok: {
        duration: number
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Duration: React.FC<DurationProps> = ({ blok }) => {
    const { duration, paddingTop, paddingBottom } = blok
    return (
        <div>
            <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
                <hr />
                <div className="row">
                    <div className="col col-sm-6">
                        <Headline
                            tag={'h4'}
                            content={`${duration}`}
                            classes={'advert fnt-utl-al--center fnt-utl-wei--600'}
                            paddingTop={'none'}
                            paddingBottom={'none'}
                        />
                        <Headline
                            tag={'h4'}
                            content={`Jahre`}
                            classes={'h4 fnt-utl-wei--500 fnt-utl-al--center'}
                            paddingTop={'none'}
                            paddingBottom={'xl'}
                        />
                    </div>
                    <div className="col col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                        <Headline tag={'h3'} content={`Dauer`} classes={'h2 fnt-utl-wei--600'} paddingTop={'none'} paddingBottom={'xl'} />
                    </div>
                </div>
                <hr />
            </Container>
        </div>
    )
}
