import React from 'react'
import GoogleMapReact from 'google-map-react'
import { motion } from 'framer-motion'

import { I_Component, T_Padding } from 'common/types/Component'
import { Headline, HeadlineProps } from 'components/Headline'

export interface MapProps extends I_Component {
    blok: {
        headline: string
        description: string
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Map: React.FC<MapProps> = ({ blok }) => {
    const { headline, description } = blok
    const defaultOptions = {
        center: {
            lat: 49.8258924,
            lng: 9.1456337
        },
        zoom: 11
    }

    const headlineProps: HeadlineProps = {
        paddingTop: 'none',
        paddingBottom: 'none',
        tag: 'h3',
        classes: 'h4 fnt-utl-clr--inverted',
        content: `${headline}`
    }

    const renderMarkers = (map: any, maps: any) => {
        let marker = new maps.Marker({
            position: {
                lat: 49.8258924,
                lng: 9.1456337
            },
            map,
            title: 'Industrie Center Obernburg'
        })
        return marker
    }

    const renderHeadline = (headline: HeadlineProps, delay: number) => {
        const { content, classes, tag } = headline
        return (
            <motion.div
                initial={{ y: '80%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                transition={{ ease: 'easeInOut', duration: 0.7, delay }}
            >
                <Headline tag={tag} content={content} classes={classes} paddingTop={'none'} paddingBottom={'none'} />
            </motion.div>
        )
    }

    const renderPanel = (description: string) => {
        return (
            <div className="hero__description">
                <Headline
                    tag={'p'}
                    content={description}
                    classes={`fnt-utl-clr--inverted fnt-utl-wei--200`}
                    paddingTop={'none'}
                    paddingBottom={'none'}
                />
            </div>
        )
    }

    return (
        <article className={`hero hero--no-slider`}>
            <div className="map">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAdxzf52fzkqB2FThrEDdttHU0ykKgTo5Q' }}
                    defaultCenter={defaultOptions.center}
                    defaultZoom={defaultOptions.zoom}
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                    style={{ height: 'inherit' }}
                ></GoogleMapReact>
            </div>
            <header style={{ position: 'relative', zIndex: 2 }} className="container hero__content-wrapper">
                <div className="hero__header">
                    {renderHeadline(
                        { tag: 'h2', content: headline, classes: 'h3 headline--as-label', paddingTop: 'none', paddingBottom: 'none' },
                        0
                    )}
                    {renderPanel(description)}
                </div>
            </header>
        </article>
    )
}
