import React from 'react'

import { useWindowSize } from 'hooks/useWindow'
import { I_Category } from 'common/types/Category'
import { I_GroupedCategory } from './index'
import { Headline } from 'components/Headline'

export interface CategoryProps {
    category: I_Category
    groupedCategories: any[]
    index: number
    activeIndex: number | undefined
    delegateClick: (catgory: I_GroupedCategory[], index: number | undefined) => void
}

export const Category: React.FC<CategoryProps> = ({ category, groupedCategories, index, activeIndex, delegateClick }) => {
    const { width } = useWindowSize()
    return (
        <article
            className={`${width && width >= 769 ? 'col-12 col-md-6 col-lg-3' : 'drag-tiles__tile '} `}
            onClick={() => delegateClick(groupedCategories, index)}
        >
            <div className={`tile ${activeIndex === index ? 'tile--is-active' : ''}`}>
                <header className="tile__header">
                    <Headline
                        tag={'h3'}
                        classes={'text fnt-utl-wei--500 fnt-utl-clr--inverted'}
                        removeBr={true}
                        content={category.type}
                        paddingTop={'none'}
                        paddingBottom={'none'}
                    />
                </header>
                {activeIndex === index ? <div className="tile__border"></div> : ''}
                <div className="tile__cover-content"></div>
                <div className="tile__thumb-wrapper">
                    <img src={category.thumb.filename} alt={category.thumb.alt} loading={'lazy'} />
                </div>
            </div>
        </article>
    )
}
