import { useStaticQuery, graphql } from 'gatsby'

export function Query() {
    return useStaticQuery(graphql`
        query Process {
            data: processJson {
                headline
                items {
                    icon
                    content
                    title
                }
            }
        }
    `)
}
