import { useStaticQuery, graphql } from 'gatsby'

export interface I_StoryblokEntryItem {
    content: {
        Meta: {
            title: string
        }
        content: any[]
    }
    full_slug: string
    field_component: string
    field_type_string: string
    field_isCategory_boolean: boolean
    field_categoryType_string: string
    id: string
    slug: string
    uuid: string
}

export interface I_StoryblokEntry {
    entry: I_StoryblokEntryItem
}

export interface I_AllStoryblokEntry {
    allStoryblokEntry: {
        entries: I_StoryblokEntry[]
    }
}

export function allStorybookEntry(): I_AllStoryblokEntry {
    return useStaticQuery(graphql`
        query AllStoryblokEntry {
            allStoryblokEntry {
                entries: edges {
                    entry: node {
                        id
                        name
                        uuid
                        slug
                        full_slug
                        field_component
                        field_isCategory_boolean
                        field_categoryType_string
                        field_type_string
                        content
                    }
                }
            }
        }
    `)
}
