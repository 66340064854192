import React from 'react'
import { Headline } from 'components/Headline'
import { Container } from 'components/Container'
import { I_Component, T_Padding } from 'common/types/Component'

export interface I_Person {
    title: string
    description: string
    list?: string[]
    contact?: string[]
}

export interface PersonsProps extends I_Component {
    blok: {
        items: I_Person[]
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Persons: React.FC<PersonsProps> = ({ blok }) => {
    const { items, paddingTop, paddingBottom } = blok
    const renderPerson = (item: I_Person, index: number) => (
        <div key={index} className="col-6 col-sm-4">
            <Headline tag={'h3'} classes="h4 fnt-utl-wei--500" content={item.title} paddingTop={'none'} paddingBottom={'none'} />
            <Headline
                tag={'h4'}
                classes="body gap-t-m help-family-neufile"
                content={item.description}
                paddingTop={'none'}
                paddingBottom={'m'}
            />
            {item.contact ? (
                <ul className="list">
                    {item.contact.map((item: string, index: number) => (
                        <li key={index} className="gap-t-s body">
                            {item}
                        </li>
                    ))}
                </ul>
            ) : (
                ''
            )}
        </div>
    )

    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <div className="row gy-5">{items.map((item, index) => renderPerson(item, index))}</div>
        </Container>
    )
}
