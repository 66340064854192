import React from 'react'
import { sortBy } from 'lodash'

import { useWindowSize } from 'hooks/useWindow'
import { I_Component, T_Padding } from 'common/types/Component'
import { Container } from 'components/Container'
import { Headline, HeadlineProps } from 'components/Headline'
import { Icon } from 'components/Icon'
import { T_Icon } from 'components/Icon/Icon'

interface I_Item {
    withIcon: boolean
    icon: string
    headline: string
    description?: string
}

export interface BulletsProps extends I_Component {
    items: I_Item[]
    blok: {
        items: string[]
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Bullets: React.FC<BulletsProps> = ({ blok }) => {
    const { width } = useWindowSize()
    const { paddingTop, paddingBottom } = blok
    const renderIcon = (icon: T_Icon) => (
        <div className="bullets__item__icon">
            <Icon type={icon} />
        </div>
    )

    const renderHeadline = (content: HeadlineProps) => {
        const { content: hContent, classes, tag, paddingTop, paddingBottom } = content
        return (
            <Headline
                tag={tag}
                content={hContent}
                classes={classes}
                removeBr={true}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
            />
        )
    }

    const renderItem = (item: string) => {
        return (
            <li className="bullets__item">
                <div className="bullets__item__wrapper">
                    {renderIcon(item as T_Icon)}
                    <div className="bullets__item__body fnt-utl-al--center">
                        {renderHeadline({
                            tag: 'h4',
                            content: item,
                            classes: `bullets__item__headline ${width < 768 ? 'body' : 'text'}  fnt-utl-wei--600 fnt-utl-trn--uppercase`,
                            paddingTop: 'none',
                            paddingBottom: 'none'
                        })}
                    </div>
                </div>
            </li>
        )
    }

    let sortedItems = sortBy(blok.items, (item) =>
        item === 'mindestens Mittlerer Schulabschluss' || item === 'mindestens Fachabitur' || item === 'mindestens Realschulabschluss'
            ? 0
            : 1
    )

    console.log(sortedItems)

    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <ul className="bullets list">
                {sortedItems.map((item: any, index) => (
                    <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
                ))}
            </ul>
        </Container>
    )
}
