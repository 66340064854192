import React, { useEffect, useRef, useState } from 'react'
import _, { sortBy } from 'lodash'

import { Container } from 'components/Container'
import { Headline } from 'components/Headline'
import { T_Padding } from 'common/types/Component'

const url = `https://ausbildungsstellen.mainsite.de`

export interface I_JobItem {
    title: string
    start: string
    year: number
    company: string
    location: string
    link: string
    coordinates: number[]
}

export interface JobsProps {
    blok: {
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

async function loadJobDetail(link: string) {
    const data = await fetch(`${link}`)
        .then(function (response) {
            return response.text()
        })
        .then(function (html) {
            let parser = new DOMParser()
            let doc = parser.parseFromString(html, 'text/html')
            let jobDetail = doc.getElementById('inhalt')
            let description = []
            let parsedJobsDetails: any = []

            const descriptionContentTags = jobDetail?.getElementsByClassName('stellenbeschreibung_aufgabe')[0].innerHTML
            const contact = jobDetail?.getElementsByClassName('stelle_webdetailskontakt')[0].innerHTML
            const tasks = jobDetail?.getElementsByClassName('stelle_webdetailsinfo')[0].innerHTML
            const requirements = jobDetail?.getElementsByClassName('stelle_webdetailsarea4')[0].innerHTML
            const details = jobDetail?.getElementsByClassName('stelle_webdetailsarea6')[0].innerHTML

            parsedJobsDetails.push(descriptionContentTags)
            parsedJobsDetails.push(tasks)
            parsedJobsDetails.push(requirements)
            parsedJobsDetails.push(details)
            parsedJobsDetails.push(contact)

            if (!descriptionContentTags) return
            for (let i = 0; i < descriptionContentTags.length; i++) {
                description.push(descriptionContentTags[i].innerText)
            }
            return parsedJobsDetails
        })
        .catch(function (err) {
            console.log('Failed to fetch page: ', err)
        })
    return data
}

async function loadExternalJobs() {
    const data = await fetch(`${url}/wp-json/wp/v2/posts`)
        .then(function (response) {
            return response.text()
        })
        .then(function (html) {
            let parser = new DOMParser()
            let doc = parser.parseFromString(html, 'text/html')
            let table = doc.getElementById('stellenTable')

            return table
        })
        .catch(function (err) {
            console.log('Failed to fetch page: ', err)
        })
    console.log(data)
    return data
}

function parseHtmlTable(element: HTMLElement) {
    const tableBody = element.getElementsByTagName('tbody')
    const tableTrCollection = tableBody && tableBody[0].getElementsByTagName('tr')

    if (!tableTrCollection) return

    const getTableTdElements = () => {
        let tableTdCollection = []
        for (let i = 0; i < tableTrCollection?.length; i++) {
            tableTdCollection.push(tableTrCollection[i].getElementsByTagName('td'))
        }
        return tableTdCollection
    }

    const parseTableHtml = (item: any[]) => {
        let jobDetailFromPortal: I_JobItem = {
            title: '',
            start: '',
            year: 0,
            company: '',
            location: '',
            link: '',
            coordinates: []
        }

        for (let i = 0; i < item.length; i++) {
            if (item.length - 1 === i) return jobDetailFromPortal

            if (i === 0) jobDetailFromPortal.company = item[i].innerText || ''
            if (i === 2) jobDetailFromPortal.location = item[i].innerText || ''
            if (i >= 3) jobDetailFromPortal.coordinates.push(parseFloat(item[i].innerText || 0))

            if (i === 1) {
                if (item[i].getElementsByTagName('a')[0] === undefined) return

                const fullUrl = item[i].getElementsByTagName('a')[0].href
                const purgedUrl = fullUrl.substring(fullUrl.indexOf('?') + 1)

                jobDetailFromPortal.link = `${url}/uebersicht/${purgedUrl.replace('&', '?')}` || ''
                jobDetailFromPortal.title = jobDetailFromPortal.title = item[i].innerText || ''

                const title = jobDetailFromPortal.title.slice(0, jobDetailFromPortal.title.lastIndexOf('-'))
                const splitStartAndYear = jobDetailFromPortal.title
                    .substring(jobDetailFromPortal.title.lastIndexOf('-') + 1)
                    .trim()
                    .split(' ')

                jobDetailFromPortal.title = title
                jobDetailFromPortal.start = splitStartAndYear[0]
                jobDetailFromPortal.year = parseInt(splitStartAndYear[1])
            }
        }
    }

    const buildJobsObject = (tableTdCollection: any[]) => {
        let jobs: Partial<I_JobItem>[] | undefined = []

        for (let i = 0; i < tableTdCollection.length; i++) {
            const data = parseTableHtml(tableTdCollection[i])
            data && jobs.push(data)
        }

        return jobs
    }

    const tdCollection = getTableTdElements()
    const data = buildJobsObject(tdCollection)

    return data
}

export const Jobs: React.FC<JobsProps> = ({ blok }) => {
    const { paddingTop, paddingBottom } = blok
    const [jobs, setJobs] = useState<{ [key: string]: Partial<I_JobItem[]>[] }>()
    const [jobDetails, setJobDetails] = useState<any>()
    const [jobLinkActive, setJobLinkActive] = useState<any>()
    const refJobs = useRef<HTMLDivElement>(null)

    useEffect(() => {
        async function load() {
            const jobsTable = await loadExternalJobs()

            if (jobsTable) {
                const data = parseHtmlTable(jobsTable)

                console.log(_.groupBy(data, 'year'))

                data && setJobs(_.groupBy(data, 'year'))
            }
        }

        load()

        setTimeout(() => {
            if (typeof window !== 'undefined') {
                const hash = window.location.hash
                if (hash === '#berufe' && refJobs && refJobs.current) {
                    window.scrollTo(0, refJobs.current.getBoundingClientRect().top + window.scrollY - 200)
                }
            }
        }, 2000)
    }, [])

    useEffect(() => {
        console.log(jobs)
    }, [jobs])

    const getJobDetail = async (link: string) => {
        const dataDetail = await loadJobDetail(link)
        return dataDetail
    }

    const checkAmpersand = (title: string) => {
        const position = title.indexOf('&')
        return position === -1 ? title : [title.slice(0, position), '%', title.slice(position)].join('')
    }

    const renderJob = (job: I_JobItem, index: number) => {
        return (
            <div id="jobs" key={index} className="jobs__item">
                <div
                    onClick={() => {
                        window.open(`${job.link}`, '_blank')
                    }}
                >
                    <Headline
                        tag={'h4'}
                        classes="text fnt-utl-wei--500 fnt-utl-trn--uppercase"
                        content={`${checkAmpersand(job.title)} - ${job.start} ${job.year}`}
                        paddingTop={'none'}
                        paddingBottom={'none'}
                        removeBr={true}
                    />
                </div>
            </div>
        )
    }

    const renderJobs = (jobs: any) => {
        const jobKey = Object.keys(jobs)
        return jobKey.map((jobKey, index) => (
            <div key={index} className="gap-t-xl">
                <h3 className="gap-b-l">{jobKey}</h3>
                <div ref={refJobs}>{sortBy(jobs[jobKey], ({ title }) => title).map((item, index) => item && renderJob(item, index))}</div>
            </div>
        ))
    }

    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            {jobs && renderJobs(jobs)}
        </Container>
    )
}
