import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { allStorybookEntry } from 'common/query'
import { I_StoryblokEntryItem } from 'common/query/allStorybookEntry'
import { I_Component, T_Padding } from 'common/types/Component'
import { IconWrapper } from 'components/Icon'
import { Container } from 'components/Container'
import { Headline } from 'components/Headline'
import { useStoryblok } from 'hooks'

export interface RecommendationsProps extends I_Component {
    blok: {
        item: string[]
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
    location: Location
    ids: string[]
}

export const Recommendations: React.FC<RecommendationsProps> = ({ paddingTop, paddingBottom, blok, location }) => {
    const {
        allStoryblokEntry: { entries }
    } = allStorybookEntry()
    let pages: I_StoryblokEntryItem[] = []

    entries.map(({ entry }) => {
        const data = useStoryblok(entry, location)
        return blok.item.map((id) => id === data.uuid && pages.push(data))
    })

    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <div className="row">
                {pages.map((item, index) => (
                    <div key={index} className={`${pages.length > 1 ? 'col g-4 col-sm-12 col-lg-4 g-l-0' : 'col'}`}>
                        <Link to={'/' + item.full_slug} title={item.content.Meta.title}>
                            <article className="recommendation">
                                <header className="recommendation__header">
                                    <Headline
                                        tag={'h3'}
                                        classes={`${entries.length === 1 ? 'subline' : 'text'} fnt-utl-clr--inverted`}
                                        content={item.content.Meta.title}
                                        paddingTop={'none'}
                                        paddingBottom={'none'}
                                    />
                                </header>
                                <footer className="recommendation__footer">
                                    <IconWrapper isOpen={false} />
                                </footer>
                                {item.content.content[0].image && (
                                    <div className="recommendation__img">
                                        <img src={item.content.content[0].image.filename} alt={`${item.content.Meta.title}`} />
                                    </div>
                                )}
                                <div className="recommendation__cover" />
                            </article>
                        </Link>
                    </div>
                ))}
            </div>
        </Container>
    )
}
