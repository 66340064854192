import React from 'react'
import { ReactComponent as IconAbschluss } from 'common/icons/abschluss.svg'
import { ReactComponent as IconAbschlussStudium } from 'common/icons/abschlussstudium.svg'
import { ReactComponent as IconGewerblichTechnischeArbeit } from 'common/icons/arbeigewerblich-technisch.svg'
import { ReactComponent as IconBegeisterungFuerNaturwissenschaften } from 'common/icons/begeisterungfuernaturwissenschaften.svg'
import { ReactComponent as IconArbeitKaufmaennisch } from 'common/icons/arbeitkaufmannisch.svg'
import { ReactComponent as IconAusbildungsbeginn } from 'common/icons/beginnausbildung.svg'
import { ReactComponent as IconAusdauerDurchalteVermoegen } from 'common/icons/ausdauerdurchhaltevermoegen.svg'
import { ReactComponent as IconKommunikationsstaerke } from 'common/icons/kommunikationsstaerke.svg'
import { ReactComponent as IconBeginnStudium } from 'common/icons/beginnstudium.svg'
import { ReactComponent as IconBelastbarkeit } from 'common/icons/belastbarkeit.svg'
import { ReactComponent as IconBewerbung } from 'common/icons/bewerbung.svg'
import { ReactComponent as IconEigeninitiative } from 'common/icons/eigeninitiative.svg'
import { ReactComponent as IconFlexibilitaet } from 'common/icons/flexibilitaet.svg'
import { ReactComponent as IconGenauigkeit } from 'common/icons/genauigkeit.svg'
import { ReactComponent as IconGewissenhaftigkeit } from 'common/icons/gewissenhaftigkeit.svg'
import { ReactComponent as IconGuteAuffassungsgabe } from 'common/icons/guteauffassungsgabe.svg'
import { ReactComponent as IconGuteBeobachtungsgabeBeobachtungsgenauigkeit } from 'common/icons/gutebeobachtungsgabebeobachtungsgenauigkeit.svg'
import { ReactComponent as IconHandwerklichesGeschick } from 'common/icons/handwerklichesgeschick.svg'
import { ReactComponent as IconHilfsbereitschaft } from 'common/icons/hilfsbereitschaft.svg'
import { ReactComponent as IconKoerperlicheBelastbarkeit } from 'common/icons/koerperlichebelastbarkeit.svg'
import { ReactComponent as IconKonzentrationsfaehigkeit } from 'common/icons/konzentrationsfaehigkeit.svg'
import { ReactComponent as IconKreativitaet } from 'common/icons/kreativitaet.svg'
import { ReactComponent as IconLogischesDenkvermoegen } from 'common/icons/logischesdenkvermoegen.svg'
import { ReactComponent as IconMedizinischeUntersuchung } from 'common/icons/medizinischeuntersuchung.svg'
import { ReactComponent as IconOrganisationsFaehigkeit } from 'common/icons/organisationsfaehigkeit.svg'
import { ReactComponent as IconVorstellungsgespraech } from 'common/icons/persoenlicheskennenlernenvorstellungsgespraech.svg'
import { ReactComponent as IconPraktikumGewerblichTechnisch } from 'common/icons/praktikumgewerblich-technisch.svg'
import { ReactComponent as IconPraktikumKaufmaennisch } from 'common/icons/praktikumkaufmaennisch.svg'
import { ReactComponent as IconProblemloesungsorientierung } from 'common/icons/problemloesungsorientierung.svg'
import { ReactComponent as IconPruefungseinstellungstest } from 'common/icons/pruefungeinstellungstest.svg'
import { ReactComponent as IconRaeumlichesvorstellungsvermoegen } from 'common/icons/raeuumlichesvorstellungsvermoegen.svg'
import { ReactComponent as IconReaktionsschnelligkeit } from 'common/icons/reaktionsschnelligkeit.svg'
import { ReactComponent as IconSelbstaendigkeit } from 'common/icons/selbststaendigkeit.svg'
import { ReactComponent as IconStandort } from 'common/icons/standort.svg'
import { ReactComponent as IconTeamfaehigkeit } from 'common/icons/teamfaehigkeit.svg'
import { ReactComponent as IconTechnischesVerstaendnisTechnikBegeisterung } from 'common/icons/technischesverstaendnistechnikbegeisterung.svg'
import { ReactComponent as IconVerantwortungsbewusstsein } from 'common/icons/verantwortungsbewusstsein.svg'
import { ReactComponent as IconVertrag } from 'common/icons/vertrag.svg'
import { ReactComponent as IconZahlenverstaendnis } from 'common/icons/zahlenverstaendnismathematischesverstaendnis.svg'
import { ReactComponent as IconZuverlaessigkeit } from 'common/icons/zuverlaessigkeit.svg'

export type T_Icon =
    | 'Abschluss'
    | 'mindestens Mittlerer Schulabschluss'
    | 'mindestens Realschulabschluss'
    | 'mindestens Fachabitur'
    | 'Abschluss Studium'
    | 'Ausdauer'
    | 'Ausbildungsbeginn'
    | 'Begeisterung für Naturwissenschaften'
    | 'Kaufmännische Arbeit'
    | 'gewerblich, technische Arbeit'
    | 'Kommunikationsstärke'
    | 'Studiumsbeginn'
    | 'Belastbarkeit'
    | 'Bewerbung'
    | 'Eigeninitiative'
    | 'Flexibilität'
    | 'Genauigkeit'
    | 'Gewissenhaftigkeit'
    | 'Gute Auffassungsgabe'
    | 'Gute Beobachtungsgabe'
    | 'Handwerkliches Geschick'
    | 'Hilfsbereitschaft'
    | 'Körperliche Belastbarkeit'
    | 'Konzentrationsfähigkeit'
    | 'Kreativität'
    | 'Logisches Denkvermögen'
    | 'Medizinische Untersuchung'
    | 'Organisationsfähigkeit'
    | 'Persönliches Kennenlernen-/Vorstellungsgespräch'
    | 'Gewerblich- / technisches Praktikum'
    | 'Kaufmännisches Praktikum'
    | 'Problemlösungsorientierung'
    | 'Prüfungs- / Einstellungstest'
    | 'Räumliches Vorstellungsvermögen'
    | 'Reaktionsschnelligkeit'
    | 'Selbstständigkeit'
    | 'Standort'
    | 'Teamfähigkeit'
    | 'Technikbegeisterung'
    | 'Verantwortungsbewusstsein'
    | 'Vertrag'
    | 'Zahlenverständnis'
    | 'Zuverlässigkeit'

export interface IconProps {
    type: T_Icon
}

export const Icon: React.FC<IconProps> = ({ type }) => {
    const selectIcon = (type: T_Icon) => {
        switch (type) {
            case 'Waben':
                return <IconWaben />
            case 'Gewissenhaftigkeit':
                return <IconGewissenhaftigkeit />
            case 'Gute Auffassungsgabe':
                return <IconGuteAuffassungsgabe />
            case 'Gute Beobachtungsgabe':
                return <IconGuteBeobachtungsgabeBeobachtungsgenauigkeit />
            case 'Handwerkliches Geschick':
                return <IconHandwerklichesGeschick />
            case 'Hilfsbereitschaft':
                return <IconHilfsbereitschaft />
            case 'Körperliche Belastbarkeit':
                return <IconKoerperlicheBelastbarkeit />
            case 'Konzentrationsfähigkeit':
                return <IconKonzentrationsfaehigkeit />
            case 'Kreativität':
                return <IconKreativitaet />
            case 'Logisches Denkvermögen':
                return <IconLogischesDenkvermoegen />
            case 'Medizinische Untersuchung':
                return <IconMedizinischeUntersuchung />
            case 'Organisationsfähigkeit':
                return <IconOrganisationsFaehigkeit />
            case 'Persönliches Kennenlernen-/Vorstellungsgespräch':
                return <IconVorstellungsgespraech />
            case 'Gewerblich- / technisches Praktikum':
                return <IconPraktikumGewerblichTechnisch />
            case 'Kaufmännisches Praktikum':
                return <IconPraktikumKaufmaennisch />
            case 'Problemlösungsorientierung':
                return <IconProblemloesungsorientierung />
            case 'Prüfungs- / Einstellungstest':
                return <IconPruefungseinstellungstest />
            case 'Räumliches Vorstellungsvermögen':
                return <IconRaeumlichesvorstellungsvermoegen />
            case 'Reaktionsschnelligkeit':
                return <IconReaktionsschnelligkeit />
            case 'Selbstständigkeit':
                return <IconSelbstaendigkeit />
            case 'Standort':
                return <IconStandort />
            case 'Teamfähigkeit':
                return <IconTeamfaehigkeit />
            case 'Technikbegeisterung':
                return <IconTechnischesVerstaendnisTechnikBegeisterung />
            case 'Verantwortungsbewusstsein':
                return <IconVerantwortungsbewusstsein />
            case 'Vertrag':
                return <IconVertrag />
            case 'Zahlenverständnis':
                return <IconZahlenverstaendnis />
            case 'Zuverlässigkeit':
                return <IconZuverlaessigkeit />
            case 'Abschluss':
                return <IconAbschluss />
            case 'mindestens Mittlerer Schulabschluss':
                return <IconAbschluss />
            case 'mindestens Realschulabschluss':
                return <IconAbschluss />
            case 'mindestens Fachabitur':
                return <IconAbschluss />
            case 'Abschluss Studium':
                return <IconAbschlussStudium />
            case 'Ausbildungsbeginn':
                return <IconAusbildungsbeginn />
            case 'Belastbarkeit':
                return <IconBelastbarkeit />
            case 'Bewerbung':
                return <IconBewerbung />
            case 'Flexibilität':
                return <IconFlexibilitaet />
            case 'Genauigkeit':
                return <IconGenauigkeit />
            case 'Eigeninitiative':
                return <IconEigeninitiative />
            case 'Ausdauer':
                return <IconAusdauerDurchalteVermoegen />
            case 'Begeisterung für Naturwissenschaften':
                return <IconBegeisterungFuerNaturwissenschaften />
            case 'gewerblich, technische Arbeit':
                return <IconGewerblichTechnischeArbeit />
            case 'Kaufmännische Arbeit':
                return <IconArbeitKaufmaennisch />
            case 'Kommunikationsstärke':
                return <IconKommunikationsstaerke />
            case 'Studiumsbeginn':
                return <IconBeginnStudium />
            default:
                return <div></div>
        }
    }

    return <div className="icon">{selectIcon(type)}</div>
}
