import { useStaticQuery, graphql } from 'gatsby'

export interface I_Category {
    name: string
    content: {
        content: {
            uid: string
            id: string
            type: string
            thumb: {
                filename: string
                alt: string
            }
        }
    }
    uuid: string
    isCategory: boolean
}

export interface I_AllCategoriesQuery {
    allStoryblokEntry: {
        categories: {
            category: I_Category
        }[]
    }
}

export function getAllCategories() {
    const data = useStaticQuery(graphql`
        query AllCategoriesQuery {
            allStoryblokEntry {
                categories: edges {
                    category: node {
                        uuid
                        content
                        name
                        isCategory: field_isCategory_boolean
                    }
                }
            }
        }
    `) as I_AllCategoriesQuery

    return data.allStoryblokEntry.categories.filter((item) => item.category.isCategory === true)
}
