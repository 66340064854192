import React from 'react'
import { orderBy } from 'lodash'

import { I_Component, T_Padding } from 'common/types/Component'

import { Headline } from 'components/Headline'
import { Container } from 'components/Container'

export interface BannerProps extends I_Component {
    blok: {
        dividerTop: boolean
        dividerBottom: boolean
        title: string
        items: string[]
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Banner: React.FC<BannerProps> = ({ blok }) => {
    const { dividerTop, dividerBottom, title, items, paddingTop, paddingBottom } = blok
    const sortedItems = orderBy(items, [(item) => item.toLowerCase()], ['asc'])
    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            {dividerTop ? <hr /> : ''}
            <div className="row">
                <div className="col col-sm-6">
                    <Headline
                        tag={'h3'}
                        classes="h2 fnt-utl-wei--600"
                        content={title}
                        removeBr={true}
                        paddingTop={'l'}
                        paddingBottom={'xl'}
                    />
                </div>
                <div className="col col-sm-6 d-flex align-items-center gap-b-m">
                    <ul className="list">
                        {sortedItems.map((item) => (
                            <li>
                                <Headline
                                    tag={'p'}
                                    classes="h4"
                                    content={item}
                                    removeBr={true}
                                    paddingTop={'none'}
                                    paddingBottom={'none'}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {dividerBottom ? <hr /> : ''}
        </Container>
    )
}
