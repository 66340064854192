import React from 'react'
import { graphql } from 'gatsby'
import { useStoryblok } from 'hooks'
import IndexLayout from 'layouts/index'

import { Page } from '../components/Page'
import { Seo } from '../components/Seo'

export interface PageAssemblerProps {
    data: any
    location: Location
}

const PageAssembler: React.FC<PageAssemblerProps> = ({ data, location }) => {
    const story = useStoryblok(data.story, location)

    return (
        <IndexLayout location={location}>
            <Seo title={story.content.Meta.title} description={story.content.Meta.description} />
            <Page blok={story.content} location={location} />
        </IndexLayout>
    )
}

export default PageAssembler

export const graphQuery = graphql`
    query ($fullSlug: String) {
        story: storyblokEntry(full_slug: { eq: $fullSlug }) {
            name
            content
            full_slug
            uuid
        }
    }
`
