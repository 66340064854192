import React from 'react'

import { DynamicComponent } from '../DynamicComponent'
import SbEditable from 'storyblok-react'

export interface PageProps {
    blok: any
    location: Location
}

export const Page: React.FC<PageProps> = ({ blok, location }) => {
    const content =
        blok.content &&
        blok.content.map(childBlok => {
            return <DynamicComponent blok={childBlok} location={location} key={childBlok._uid} />
        })

    return (
        <SbEditable content={blok} key={blok._uid}>
            {content}
        </SbEditable>
    )
}
