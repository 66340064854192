import React, { useState } from 'react'

import { I_Component, T_Padding } from 'common/types/Component'
import { Container } from 'components/Container'
import { Headline } from 'components/Headline'
import { IconWrapper, Icon } from 'components/Icon'
import { Query } from './Query'

export interface ProcessProps extends I_Component {
    blok: {
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const Process: React.FC<ProcessProps> = ({ blok }) => {
    const { paddingTop, paddingBottom } = blok
    const index = 0
    const [activeIndex, setActiveIndex] = useState(-1)
    const { data } = Query()
    const { headline, items } = data

    return (
        <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <div
                onClick={() => setActiveIndex((aI) => (aI === -1 ? 0 : -1))}
                className={`accordeon__item accordeon__item--big ${activeIndex === index ? 'accordeon__item--is-open' : ''}`}
            >
                <div className="accordeon__item__header">
                    <Headline tag={'h3'} classes="h3" content={headline} paddingTop={'none'} paddingBottom={'none'} />
                    <IconWrapper isOpen={activeIndex === index} />
                </div>
                <div className="accordeon__item__body">
                    <div className="gap-t-xl">
                        {items.map((item: any, index: number) => (
                            <div key={index} className="row">
                                <div className="col-12 col-sm-3 gx-4">
                                    {item.icon ? (
                                        <div className="process__icon">
                                            <Icon type={item.icon} />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="col-12 col-sm-9">
                                    <Headline
                                        tag={'h4'}
                                        classes="h4 fnt-utl-wei--600"
                                        content={item.title}
                                        paddingTop={'none'}
                                        paddingBottom={'s'}
                                    />
                                    <Headline tag={'p'} classes="text" content={item.content} paddingTop={'none'} paddingBottom={'xl'} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    )
}
