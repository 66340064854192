import React from 'react'
import { motion } from 'framer-motion'
import { I_Component } from 'common/types/Component'
import { Headline, HeadlineProps } from 'components/Headline'
import { FluidImage } from 'components/FluidImage'

export interface HeroProps extends I_Component {
    blok: {
        headline: string
        description: string
        image?: {
            filename: string
            key: string
            alt: string
        }
        classes?: string
    }
    location: Location
}

export const Hero: React.FC<HeroProps> = ({ blok, location }) => {
    const { headline, description, image } = blok
    const renderHeadline = (headline: HeadlineProps, delay: number) => {
        const { content, classes, tag } = headline
        return (
            <motion.div
                key={location.pathname}
                initial={{ y: '80%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                transition={{ ease: 'easeInOut', duration: 0.7, delay }}
            >
                <Headline tag={tag} content={content} classes={classes} paddingTop={'none'} paddingBottom={'none'} />
            </motion.div>
        )
    }

    return (
        <>
            <article className={`hero hero--no-slider`} style={{ height: 90 }}>
                {image && image?.filename !== '' && (
                    <div className="hero__image-wrapper">
                        <motion.div
                            key={location.pathname}
                            initial={{ scale: 1.1, y: '5%' }}
                            animate={{ scale: 1, y: 0 }}
                            exit={{ scale: 1.1, y: '5%' }}
                            transition={{ ease: 'easeInOut', duration: 0.7 }}
                        >
                            <img className="hero__image" src={image.filename} alt={image.alt} loading={'eager'} />
                        </motion.div>
                    </div>
                )}
            </article>
            <header className="container hero__content-wrapper">
                <div className="hero__header">
                    {renderHeadline(
                        { tag: 'h2', content: headline, classes: 'h3 headline--as-label', paddingTop: 'none', paddingBottom: 'none' },
                        0
                    )}
                    {description && (
                        <motion.p
                            className="hero__description text fnt-utl-wei--400 fnt-utl-clr--inverted fnt-utl-line-height--text"
                            initial={{ y: '80%', opacity: 0 }}
                            animate={{ y: '0%', opacity: 1 }}
                            exit={{ y: '-80%', opacity: 1 }}
                            transition={{ ease: 'easeInOut', duration: 0.7, delay: 0.35 }}
                        >
                            {description}
                        </motion.p>
                    )}
                </div>
            </header>
        </>
    )
}
