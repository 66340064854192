import { Transition } from 'framer-motion'

export function TransitionGlobal(duration: number, delay: number): Transition {
    return {
        delay,
        duration,
        ease: [0.76, 0, 0.24, 1],
        when: 'beforeChildren'
    }
}
