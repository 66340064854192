import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'
import { isEqual, isEmpty, xorWith } from 'lodash'

import { sbClient } from 'hooks/useStoryblok'
import { Headline } from 'components/Headline'
import { IconWrapper } from 'components/Icon'
import { T_Padding } from 'common/types/Component'
import { TransitionGlobal } from 'common/animations'

export interface AccordeonItemProps {
    title: string
    description: any
    activeIndex: number | undefined
    index: number
    image?: {
        filename: string
        key: string
    }
    delegateClick: (index: number | undefined) => void
    link?: string
}

export interface AccordeonProps {
    classes?: string
    items?: any
    blok?: {
        items: AccordeonItemProps[]
        paddingTop: T_Padding
        paddingBottom: T_Padding
    }
}

export const AccordeonItem: React.FC<AccordeonItemProps> = ({ title, description, image, link, index, activeIndex, delegateClick }) => {
    const renderAccordeonTitle = (title: string) =>
        Headline({
            tag: 'h3',
            content: title,
            classes: 'text fnt-utl-wei--500 fnt-utl-trn--uppercase',
            paddingTop: 'none',
            paddingBottom: 'none',
            removeBr: true
        })

    const renderLink = (link: string) => {
        return (
            <a href={`/${link}`} target={'_blank'}>
                <span className="gap-r-m">{`Mehr erfahren`}</span>
                <i className="icon-circle">
                    <span className="hero__icon icon">→</span>
                </i>
            </a>
        )
    }

    console.log(description)

    const html = sbClient.richTextResolver.render(description)
    return (
        <article key={index} className={`accordeon__item ${activeIndex === index && 'accordeon__item--is-open'}`}>
            <header className="accordeon__item__header" onClick={() => delegateClick(activeIndex === index ? undefined : index)}>
                {renderAccordeonTitle(title)}
                <IconWrapper isOpen={activeIndex === index} />
            </header>
            <div className="accordeon__item__body">
                <div className="accordeon__item__body__inner">
                    <div
                        className={`text fnt-utl-line-height--text fnt-utl-wei--300 ${image ? 'gap-b-xl' : ''} `}
                        dangerouslySetInnerHTML={{ __html: html || description }}
                    />
                    {image && image?.filename !== '' ? <img src={image.filename} alt={title} /> : ''}
                    {link ? (
                        <>
                            <br />
                            <br />
                            {renderLink(link)}
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </article>
    )
}

export const Accordeon: React.FC<AccordeonProps> = ({ blok, items, classes }) => {
    const [accItemOpenState, setAccItemOpenState] = useState<number | undefined>(undefined)
    const [itemState, setItemState] = useState<any>([])

    useEffect(() => {
        setItemState([])
        setTimeout(() => {
            setItemState(items)
        }, 750)
    }, [items])

    if (blok) {
        return (
            <div className={`accordeon accordeon--bg-white ${classes ? classes : ''}`}>
                <div className="container">
                    <AnimatePresence>
                        {blok.items && (
                            <motion.div key={'index'} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                {blok.items.map((item: AccordeonItemProps, index) => (
                                    <React.Fragment key={index}>
                                        <AccordeonItem
                                            title={item.title}
                                            description={item.description}
                                            link={item.link}
                                            index={index}
                                            image={item.image}
                                            activeIndex={accItemOpenState}
                                            delegateClick={(index) => {
                                                setAccItemOpenState(index)
                                            }}
                                        />
                                    </React.Fragment>
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`accordeon accordeon--bg-white ${classes ? classes : ''}`}>
                <div className="container">
                    <AnimatePresence>
                        {itemState === items && (
                            <motion.div
                                key={'index'}
                                initial={{ opacity: 0, y: '-30%' }}
                                animate={{ opacity: 1, y: '0%' }}
                                exit={{ opacity: 0, y: '-30%' }}
                                transition={TransitionGlobal(0.75, 0.15)}
                            >
                                {itemState.map((item: AccordeonItemProps, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <AccordeonItem
                                                title={item.title}
                                                description={item.description}
                                                link={item.link}
                                                index={index}
                                                image={item.image}
                                                activeIndex={accItemOpenState}
                                                delegateClick={(index) => setAccItemOpenState(index)}
                                            />
                                        </React.Fragment>
                                    )
                                })}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        )
    }
}
