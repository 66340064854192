import React, { useState, useRef, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { allStorybookEntry } from 'common/query'
import { useWindowSize } from 'hooks/useWindow'
import { I_Component } from 'common/types/Component'
import { Container } from 'components/Container'
import { Headline } from 'components/Headline'
import { DynamicComponent } from 'components/DynamicComponent'
import { useStoryblok } from 'hooks'
import { TransitionGlobal } from 'common/animations'

export interface InternshipsProps extends I_Component {
    blok: {
        items: string[]
    }
    location: Location
}

export const Internships: React.FC<InternshipsProps> = ({ blok, paddingTop, paddingBottom, location }) => {
    const { width } = useWindowSize()
    const [shouldDrag, setShouldDrag] = useState<boolean>(false)
    const refTile = useRef<HTMLDivElement>(null)
    const refContainer = useRef<HTMLDivElement>(null)
    const {
        allStoryblokEntry: { entries }
    } = allStorybookEntry()

    let interns: any[] = []

    useEffect(() => {
        if (width && width > 769) {
            setShouldDrag(() => false)
        } else if (width && width < 769) {
            setShouldDrag(() => true)
        }
    }, [width])

    blok.items.map((item) => {
        entries.map(({ entry }) => {
            if (entry.uuid === item) {
                const story = useStoryblok(entry, location)
                interns.push(story)
            }
        })
    })

    const [pageCurrent, setPageCurrent] = useState<any>()

    const renderContent = (page: any) => {
        setPageCurrent(page)

        // setTimeout(() => {
        //     if (typeof window !== 'undefined' && refTile && refTile.current) {
        //         window.scrollTo(0, refTile.current.getBoundingClientRect().top + window.scrollY)
        //     }
        // }, 500)
    }

    const renderBloks = (bloks: any) => {
        const content =
            bloks.content.content &&
            bloks.content.content.map((childBlok: any) => {
                return <DynamicComponent blok={childBlok} location={location} key={childBlok._uid} />
            })

        return content
    }

    const renderTiles = () =>
        interns.map((item: any, index: number) => (
            <div
                onClick={() => {
                    renderContent(item)
                    setTimeout(() => {
                        if (typeof window !== 'undefined' && refTile && refTile.current) {
                            window.scrollTo(0, refTile.current.getBoundingClientRect().top + window.scrollY - 200)
                        }
                    }, 0)
                }}
                key={index}
                className={shouldDrag ? 'drag-tiles__tile' : 'col-12 gy-3 col-sm-6 col-lg-3'}
            >
                <div className={`tile ${pageCurrent && pageCurrent.uuid === item.uuid ? 'tile--is-active' : ''}`}>
                    <div className="tile__header">
                        <Headline
                            tag="h3"
                            classes="text fnt-utl-clr--inverted"
                            content={item.name}
                            paddingTop={'none'}
                            paddingBottom={'none'}
                        />
                    </div>
                    {pageCurrent && pageCurrent.uuid === item.uuid ? <div className="tile__border"></div> : ''}
                    {item.content.Meta.og_image && (
                        <>
                            <div className="tile__cover-content" />
                            <div className="tile__thumb-wrapper">
                                <img className="tile__thumb" src={item.content.Meta.og_image} />
                            </div>
                        </>
                    )}
                    <div className="tile__cover"></div>
                </div>
            </div>
        ))

    return (
        <>
            <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
                {shouldDrag ? (
                    <motion.div
                        drag={'x'}
                        dragConstraints={{ left: -(240 * (interns.length - 1)), right: 0 }}
                        style={{ width: 240 * interns.length }}
                        className={`${'drag-tiles'}`}
                    >
                        {renderTiles()}
                    </motion.div>
                ) : (
                    <div ref={refContainer} className="row">
                        {renderTiles()}
                    </div>
                )}
            </Container>
            <div ref={refTile} id="internships-übersicht" style={{ marginTop: '32px', backgroundColor: '#ededed', overflow: 'hidden' }}>
                <Container paddingTop={paddingTop} paddingBottom={paddingBottom}>
                    <AnimatePresence>
                        {pageCurrent && (
                            <motion.div
                                key={'bloks'}
                                initial={{ maxHeight: 0 }}
                                animate={{ maxHeight: 2000 }}
                                transition={TransitionGlobal(1.25, 0)}
                            >
                                {renderBloks(pageCurrent)}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Container>
            </div>
        </>
    )
}
